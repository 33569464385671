<template>
  <v-form v-model="valid">
    <v-container class="pa-0" fluid>
      <v-row dense>
        <v-col>
          <v-text-field
            v-model="localFormData.CAPTION"
            :rules="[rules.required]"
            :label="$t('FORMS.BADGE_ORDER.CAPTION') + ' *'"
            :hint="$t('FORMS.BADGE_ORDER.EXAMPLE')"
            persistent-hint
            solo
            counter="30"
            :append-icon="errors.caption ? 'mdi-alert-circle-outline' : null"
            @update:error="errors = { ...errors, caption: $event }"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-select
            v-model="localFormData.REASON"
            :rules="[rules.required]"
            :items="items"
            :label="$t('FORMS.BADGE_ORDER.REASON') + ' *'"
            hide-details
            solo
            :append-icon="errors.reason ? 'mdi-alert-circle-outline' : 'mdi-chevron-down'"
            @update:error="errors = { ...errors, reason: $event }"
          ></v-select>
        </v-col>

        <v-col v-if="localFormData.REASON == $t('FORMS.BADGE_ORDER.REASONS.OTHER')" cols="12">
          <v-textarea
            v-model="localFormData.DETAILS"
            :rules="[rules.required]"
            :counter="1500"
            rows="3"
            :label="$t('FORMS.BADGE_ORDER.DETAILS')"
            hide-details
            solo
            :append-icon="errors.details ? 'mdi-alert-circle-outline' : null"
            @update:error="errors = { ...errors, details: $event }"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row dense text-center>
        <v-col cols="12">
          <slot name="button" :valid="valid" />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <slot name="description" />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Rules from "@/app/modules/_global/classes/Rules";

export default {
  name: "BadgeOrderForm",

  model: {
    prop: "formData"
  },

  props: {
    formData: {
      required: true,
      type: Object
    }
  },

  data: vm => ({
    localFormData: null,

    status: null,
    items: [
      vm.$t("FORMS.BADGE_ORDER.REASONS.MARRIAGE"),
      vm.$t("FORMS.BADGE_ORDER.REASONS.DIVORCE_SEPARATION"),
      vm.$t("FORMS.BADGE_ORDER.REASONS.MOVE_OF_TENANT"),
      vm.$t("FORMS.BADGE_ORDER.REASONS.OTHER")
    ],

    valid: true,
    errors: {},

    rules: {
      required: Rules.required
    }
  }),

  watch: {
    localFormData: {
      deep: true,
      handler: "setFormData"
    }
  },

  created() {
    this.localFormData = Object.assign({}, this.formData);
  },

  methods: {
    setFormData() {
      this.$emit("input", this.localFormData);
    }
  }
};
</script>
